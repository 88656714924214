import React, { useRef, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { NavLink, useNavigate } from "react-router-dom";

import {
    Avatar,
    Box,
    Button,
    Divider,
    List,
    ListItem,
    ListItemText,
    Popover,
    Typography,
    styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import SupportIcon from "@mui/icons-material/Support";
import ContactPageTwoToneIcon from "@mui/icons-material/ContactPageTwoTone";

const UserBoxButton = styled(Button)(
    ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
    ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

const handleClick = () => {
    window.open("https://support.mrkt365.com/");
};

function HeaderUserbox() {
    // const { user } = useContext(AuthContext);

    const { t } = useTranslation();

    const navigate = useNavigate();
    const { user, basic_info } = useAuth();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        try {
            handleClose();
            navigate("/logout");
        } catch (err) {

        }
    };

    return (
        <>
            <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
                <Avatar variant="rounded" alt={user.name} src={basic_info?.profile_pic} />
                <Box
                    component="span"
                    sx={{
                        display: "none",
                        "@media screen and (min-width: 1200px)": {
                            display: "inline-block",
                        },
                    }}
                >
                    <UserBoxText>
                        <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
                        <UserBoxDescription variant="body2">
                            {user.first_name + " " + user.last_name}
                        </UserBoxDescription>
                    </UserBoxText>
                </Box>
                <Box
                    component="span"
                    sx={{
                        display: "none",
                        "@media screen and (min-width: 1200px)": {
                            display: "inline-block",
                        },
                    }}
                >
                    <ExpandMoreTwoToneIcon
                        sx={{
                            ml: 1,
                        }}
                    />
                </Box>
            </UserBoxButton>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuUserBox
                    sx={{
                        minWidth: 210,
                    }}
                    display="flex"
                >
                    <Avatar variant="rounded" alt={user.name} src={basic_info?.profile_pic} />
                    <UserBoxText>
                        <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
                        <UserBoxDescription variant="body2">
                            {user.first_name + " " + user.last_name}
                        </UserBoxDescription>
                        <UserBoxDescription variant="body2">Customer ID: {basic_info.profile_id}</UserBoxDescription>
                    </UserBoxText>
                </MenuUserBox>
                {user.user_type !== 1 && user.user_type !== 5 && user.user_type !== 4 ? (
                    <>
                        <Divider
                            sx={{
                                mb: 0,
                            }}
                        />
                        <List
                            sx={{
                                p: 1,
                            }}
                            component="nav"
                        >
                            <ListItem
                                onClick={() => {
                                    handleClose();
                                }}
                                button
                                to={`/profile`}
                                component={NavLink}
                            >
                                <AccountBoxTwoToneIcon fontSize="small" />
                                <ListItemText primary={t("Profile")} />
                            </ListItem>
                        </List>
                        <Divider />
                        <List
                            sx={{
                                px: 1,
                                py: 0,
                            }}
                            component="nav"
                        >
                            <ListItem
                                onClick={() => {
                                    handleClose();
                                }}
                                button
                                to={`/import-reviews`}
                                component={NavLink}
                            >
                                <StarHalfIcon fontSize="small" />
                                <ListItemText primary={t("Import Reviews")} />
                            </ListItem>
                        </List>
                        <Divider />
                    </>
                ) : null}
                {user.user_type === 1 ? (
                    <>
                        <Divider
                            sx={{
                                mb: 0,
                            }}
                        />
                        <List
                            sx={{
                                p: 1,
                            }}
                            component="nav"
                        >
                            <ListItem
                                onClick={() => {
                                    handleClose();
                                }}
                                button
                                to={`/business-profile`}
                                component={NavLink}
                            >
                                <AccountBoxTwoToneIcon fontSize="small" />
                                <ListItemText primary={t("Profile")} />
                            </ListItem>
                        </List>
                    </>
                ) : null}
                <Divider
                    sx={{
                        mb: 0,
                    }}
                />
                <List
                    sx={{
                        px: 1,
                        py: 0,
                    }}
                    component="nav"
                >
                    <ListItem
                        onClick={() => {
                            handleClose();
                        }}
                        button
                        to={`/contact-support`}
                        target="_blank"
                        component={NavLink}
                    >
                        <ContactPageTwoToneIcon fontSize="small" />
                        <ListItemText primary={t("Contact Support")} />
                    </ListItem>
                </List>
                {/* <Divider /> */}

                {/* <List
                    sx={{
                        px: 1,
                        py: 0,
                    }}
                    component="nav"
                >
                    <ListItem
                        button
                        onClick={() => {
                            handleClick();
                        }}
                    >
                        <SupportIcon />
                        <ListItemText primary={t("Help & Support")} />
                    </ListItem>
                </List> */}

                <Box m={1}>
                    <Button color="primary" fullWidth onClick={handleLogout}>
                        <LockOpenTwoToneIcon
                            sx={{
                                mr: 1,
                            }}
                        />
                        {t("Sign out")}
                    </Button>
                </Box>
            </Popover>
        </>
    );
}

export default HeaderUserbox;
