import React from "react";
import { useRoutes } from "react-router-dom";
import router from "src/router";
import { SnackbarProvider } from "notistack";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useAuth from "src/hooks/useAuth";
import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import AppInit from "./components/AppInit";
import { AppContextProvider } from "./contexts/AppContext";

function App() {
    const content = useRoutes(router);
    const auth = useAuth();

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={6}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    <AppContextProvider>
                        <CssBaseline />
                        {auth.isInitialized ? content : <AppInit />}
                    </AppContextProvider>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
export default App;
